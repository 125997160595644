.Login__field .form-control{
    border: 1px solid;
    border-radius: 6px;
    padding-left: 10px;
}
.Login__field .text-muted,
.Login__field .text-muted > a {
    color: rgba(255,255,255, 0.5)!important;
}
.Login__field .text-muted > a {
    text-decoration: underline;
}
