.RecoCompleteness__panel {
    position: relative;
    height: auto;
    max-width: 450px;
    margin: 0 auto;
}
.RecoCompleteness__panel p {
    margin-bottom: 15px;
}
.RecoCompleteness__title {
    color: #202020;
    font-size: 19px;
    letter-spacing: 0;
    line-height: 25px;
    margin-top: 0;
    margin-bottom: 0;
    word-break: break-word;
}
.RecoCompleteness__semantic {
    font-size: 13px;
    color: #aaaaaa;
    margin-bottom: 10px;
    font-weight: 600;
}

.RecoCompleteness__panel .bg-empty {
    background-color: #555;
}
.RecoCompleteness__panel .bg-other {
    background-color: #d5d5d5;
}
.RecoCompleteness__prep.Report__panel {
    margin-left: auto;
    padding-bottom: 100px;
    margin-bottom: 250px;
}
.RecoCompleteness__actions {
    position: absolute;
    bottom: -250px;
    color: #2D343D;
    font-size: 17px;
    width: 320px;
    width: 80%;
    max-width: 360px;
    padding: 0;
}
.RecoCompleteness__tdsactions {
    background-color: #F6F6F6;
}
.RecoCompleteness__prepactions,
.RecoCompleteness__tdsactions {
    padding: 20px;
}
.RecoCompleteness__prepactions p:last-of-type {
    margin-bottom: 0;
}
.RecoCompleteness__fakefield > label {
    color: #969696;
    font-size: 12px;
    font-weight: bold;
}
.RecoCompleteness__fakefield > div {
    border-bottom: 1px solid #ccc;
    margin-bottom: 15px;
}
