body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.inconsolata {
	font-family: Inconsolata;
}

/* source-sans-pro-200 - latin */
@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 200;
	src: local('Source Sans Pro ExtraLight'), local('SourceSansPro-ExtraLight'),
		url('./fonts/source-sans-pro-v9-latin/source-sans-pro-v9-latin-200.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url('./fonts/source-sans-pro-v9-latin/source-sans-pro-v9-latin-200.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* source-sans-pro-200italic - latin */
@font-face {
	font-family: 'Source Sans Pro';
	font-style: italic;
	font-weight: 200;
	src: local('Source Sans Pro ExtraLight Italic'), local('SourceSansPro-ExtraLightIt'),
		url('./fonts/source-sans-pro-v9-latin/source-sans-pro-v9-latin-200italic.woff2') format('woff2'),
		url('./fonts/source-sans-pro-v9-latin/source-sans-pro-v9-latin-200italic.woff') format('woff');
}
/* source-sans-pro-300 - latin */
@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 300;
	src: local('Source Sans Pro Light'), local('SourceSansPro-Light'),
		url('./fonts/source-sans-pro-v9-latin/source-sans-pro-v9-latin-300.woff2') format('woff2'),
		url('./fonts/source-sans-pro-v9-latin/source-sans-pro-v9-latin-300.woff') format('woff');
}
/* source-sans-pro-300italic - latin */
@font-face {
	font-family: 'Source Sans Pro';
	font-style: italic;
	font-weight: 300;
	src: local('Source Sans Pro Light Italic'), local('SourceSansPro-LightIt'),
		url('./fonts/source-sans-pro-v9-latin/source-sans-pro-v9-latin-300italic.woff2') format('woff2'),
		url('./fonts/source-sans-pro-v9-latin/source-sans-pro-v9-latin-300italic.woff') format('woff');
}
/* source-sans-pro-regular - latin */
@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 400;
	src: local('Source Sans Pro'), local('SourceSansPro-Regular'),
		url('./fonts/source-sans-pro-v9-latin/source-sans-pro-v9-latin-regular.woff2') format('woff2'),
		url('./fonts/source-sans-pro-v9-latin/source-sans-pro-v9-latin-regular.woff') format('woff');
}
/* source-sans-pro-italic - latin */
@font-face {
	font-family: 'Source Sans Pro';
	font-style: italic;
	font-weight: 400;
	src: local('Source Sans Pro Italic'), local('SourceSansPro-It'),
		url('./fonts/source-sans-pro-v9-latin/source-sans-pro-v9-latin-italic.woff2') format('woff2'),
		url('./fonts/source-sans-pro-v9-latin/source-sans-pro-v9-latin-italic.woff') format('woff');
}
/* source-sans-pro-600 - latin */
@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 600;
	src: local('Source Sans Pro Semibold'), local('SourceSansPro-Semibold'),
		url('./fonts/source-sans-pro-v9-latin/source-sans-pro-v9-latin-600.woff2') format('woff2'),
		url('./fonts/source-sans-pro-v9-latin/source-sans-pro-v9-latin-600.woff') format('woff');
}
/* source-sans-pro-600italic - latin */
@font-face {
	font-family: 'Source Sans Pro';
	font-style: italic;
	font-weight: 600;
	src: local('Source Sans Pro Semibold Italic'), local('SourceSansPro-SemiboldIt'),
		url('./fonts/source-sans-pro-v9-latin/source-sans-pro-v9-latin-600italic.woff2') format('woff2'),
		url('./fonts/source-sans-pro-v9-latin/source-sans-pro-v9-latin-600italic.woff') format('woff');
}
/* source-sans-pro-700 - latin */

@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 700;
	src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
		url('./fonts/source-sans-pro-v9-latin/source-sans-pro-v9-latin-700.woff2') format('woff2'),
		url('./fonts/source-sans-pro-v9-latin/source-sans-pro-v9-latin-700.woff') format('woff');
}
/* source-sans-pro-700italic - latin */
@font-face {
	font-family: 'Source Sans Pro';
	font-style: italic;
	font-weight: 700;
	src: local('Source Sans Pro Bold Italic'), local('SourceSansPro-BoldIt'),
		url('./fonts/source-sans-pro-v9-latin/source-sans-pro-v9-latin-700italic.woff2') format('woff2'),
		url('./fonts/source-sans-pro-v9-latin/source-sans-pro-v9-latin-700italic.woff') format('woff');
}
/* source-sans-pro-900 - latin */
@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 900;
	src: local('Source Sans Pro Black'), local('SourceSansPro-Black'),
		url('./fonts/source-sans-pro-v9-latin/source-sans-pro-v9-latin-900.woff2') format('woff2'),
		url('./fonts/source-sans-pro-v9-latin/source-sans-pro-v9-latin-900.woff') format('woff');
}
/* source-sans-pro-900italic - latin */
@font-face {
	font-family: 'Source Sans Pro';
	font-style: italic;
	font-weight: 900;
	src: local('Source Sans Pro Black Italic'), local('SourceSansPro-BlackIt'),
		url('./fonts/source-sans-pro-v9-latin/source-sans-pro-v9-latin-900italic.woff2') format('woff2'),
		url('./fonts/source-sans-pro-v9-latin/source-sans-pro-v9-latin-900italic.woff') format('woff');
}
body {
	font-family: 'Source Sans Pro';
	font-size: 20px;
	color: #1c1e21;
}
h1 {
	font-style: normal;
	font-weight: 600;
	font-size: 3rem;
}
h2 {
	font-size: 2rem;
}
h1,
h2 {
	font-family: 'gelasio';
}
h5 {
	margin-top: 1rem;
	margin-bottom: 1rem;
}
h6 {
	margin-top: 2rem;
	font-size: 1.2rem;
}
.btn {
	font-size: 20px;
	border-radius: 6px;
	padding: 0.5em 1.5em;
	font-weight: 600;
}
.btn-sm {
	font-size: 12px;
}
.btn-success {
	background-color: #82bd41;
	border-color: #82bd41;
}
.bg-success {
	background-color: #82bd41!important;
}
.btn-secondary {
	color: White;
	border: 1px solid #ff6d70;
	background: #ff6d70;
}
.btn-secondary:hover {
	color: #1c1e21;
	border-color: #ff6d70;
	background-color: #ff6d70;
	opacity: 1;
}
.btn-outline-secondary {
	border-color: #ff6d70;
	color: #ff6d70;
	border-width: 2px;
}
.btn-outline-secondary:hover {
	border-color: #ff6d70;
	background-color: #ff6d70;
	color: #1c1e21;
}
.btn:disabled:hover,
.btn:disabled:hover {
	color: #ff6d70;
}
a {
	font-weight: 600;
}
a:hover {
	color: #91d1ed;
	opacity: 0.9;
}
.input-group .input-group-append .btn {
	font-size: 0.5rem;
}
#logo {
	margin: 25px;
	width: 150px;
}
