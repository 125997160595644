.upload-row {
	width: 100%;
	max-width: 600px;
	margin: 0 auto;
}
.upload h1 {
	padding-top: 64px;
}
.upload .upload__subtitle {
	margin-bottom: 2rem;
	line-height: 1.2;
}
.form-control,
.form-control:focus {
	color: White;
	background-color: transparent;
	border-radius: 0;
	border: none;
	border-bottom: 1px solid #C6C6C6;
	padding-left: 0;
}

/* try to fix windows dropdown */
select.form-control > option {
	color: initial;
	background-color: initial;
}

.required > label::after {
	content: ' *';
	color: red;
}
.file-upload .form-control-zone {
	padding: 15px;
	border: 2px dashed #979797;
	border-radius: 6px;
	font-size: 16px;
}
.file-upload .form-control-zone i {
	margin-right: 10px;
}
.file-upload .form-control-zone p {
	margin-bottom: 0;
	margin-top: 5px;
	font-weight: 600;
}
label {
	font-size: 16px;
}
.file-upload__label {
	color: #91d1ed;
	padding-left: 6px;
	cursor: pointer;
	margin-bottom: 0;
	font-weight: 600;
}
.file-upload__label:hover {
	opacity: 0.9;
	text-decoration: underline;
}
.fa-upload {
	color: #91d1ed;
}
.trial-btns {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}
.trial-btns .btn {
	font-size: 18px;
	border: 1px solid #979797;
	margin: 0 5px;
}
.trial-btns .btn:hover {
	border: 1px solid White;
}
.trial-btns .btn i {
	margin-left: 10px;
}
.trial-text {
	text-align: center;
	font-size: 16px;
}
.terms {
	color: #91d1ed;
}
.upload__btn {
	color: #ff6d70;
	background-color: White;
	margin-bottom: 30px;
	border-radius: 25px;
}
.form-group {
	margin-bottom: 1.5rem;
}
.upload select option {
	color: #1E2125 !important;
}
.form-check-input {
	margin-top: 0.6rem;
}
#delimiter {
	width: 220px;
	font-size: 20px;
}
#emailHelp {
	font-style: italic;
}
.alert {
	font-size: 18px;
	margin-top: 10px;
}
.alert-info {
	color: #3B4549;
	background: #C8E8F6;
}
.alert-info a, .alert-info a:hover {
	color: #3B4549;
	text-decoration: underline;
}
.alert-warning {
	color: #4A4435;
	background: #FAE7B5;
}
.alert-warning a, .alert-warning a:hover {
	color: #4A4435;
	text-decoration: underline;
}
.alert-danger {
	color: #4C3A3B;
	background: #FFC5C6;
}
.alert-danger a, .alert-danger a:hover {
	color: #4C3A3B;
	text-decoration: underline;
}
.alert-success {
	color: #3D4435;
	background: #CDE5B3;
}
.alert-success a, .alert-success a:hover {
	color: #3D4435;
	text-decoration: underline;
}
.alert-dismissible .close {
	top: 2px;
}
.spinner-border {
	display: inherit;
	margin: 10px auto;
}
.spinner-border.text-dark {
	color: #6F787F!important;
}
.upload .progress {
	background-color: #6F787F;
	margin-top: 20px;
	margin-bottom: 20px;
}
.upload .progress .bg-info {
	background-color: #90d1ed!important;
}
