.RecoValidity__panel {
	display: flex;
	max-width: 450px;
	margin: 0 auto;
}
.RecoValidity__panel .bg-empty {
    background-color: #555;
}
#uniqueness-img {
	max-width: 400px;
	display: block;
	margin: 0 auto;
}
.RecoValidity__title {
	color: #202020;
	font-size: 19px;
	letter-spacing: 0;
	line-height: 25px;
	margin-top: 0;
	margin-bottom: 0;
	word-break: break-word;
}
.RecoValidity__semantic {
	font-size: 13px;
	color: #aaaaaa;
	margin-bottom: 10px;
	font-weight: 600;
}

.RecoValidity__list {
	font-size: 18px;
	line-height: 25px;
	margin-top: 30px;
	margin-bottom: 15px;
}
.RecoValidity__value {
	font-weight: bold;
}

.RecoValidity__patterns {
	font-size: 14px;
	margin: auto 0;
	width: 46%;
}
.RecoValidity__patterns > h5 {
	font-family: inconsolata;
	font-size: 18px;
	margin-left: 20px;
	margin-bottom: 10px;
}
.RecoValidity__pattern {
	position: absolute;
	left: 20px;
	font-weight: bold;
}

.RecoValidity__patterns .progress {
	border-radius: 0;
	height: 23px;
	position: relative;
	background-color: transparent;
	margin: 4px 0;
}
.RecoValidity__patterns .progress-bar {
	background-color: #E5DAD2;
	color: #323E48;
}
.btn-fake {
	height: 36px;
	border-radius: 5px;
	background-color: #82BD41;
	font-size: 16px;
	font-weight: 600;
	color: #FFFFFF;
	text-align: center;
	display: inline-block;
	padding: 8px 29px;
	line-height: 20px;
}
