.ReportDetailLink__container {
    border: 1px solid #FF6D70;
    margin: 10px 0;
    padding: 15px;
    display: flex;
    height: auto;
    border-radius: 6px;
}

.ReportDetailLink__icon {
    margin: auto 15px;
    color: #FF6D70;
    width: 25px;
}

.ReportDetailLink__title {
    font-size: 20px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 0;
}

.ReportDetailLink__description {
    font-size: 18px;
    margin-left: 10px;
    flex: 1;
}
.ReportDetailLink__description p {
    margin-bottom: 0;
}
.ReportDetailLink__link {
    margin: auto 15px;
    color: #FF6D70;
    font-size: 16px;
}
.ReportDetailLink__link:hover {
    margin: auto 15px;
    color: #b1173e;
    text-decoration: none;
}
.ReportDetailLink__link .fa {
    padding-left: 10px;
}
.ti-hand-like {
    fill: white;
}
@media only screen and (min-width: 1200px) {
    .ReportDetailLink__container {
        height: 85px;
    }
}
