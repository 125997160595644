.tc-pie-chart-icon {
	font-size: 18px;
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: center;
	text-align: left;

	background-color: transparent;
	border: none;

	box-shadow: none;
	line-height: unset;
	padding: 0;
	margin: 10px 0 50px;
}
.tc-pie-chart-bottom {
	flex-direction: column;
}
.tc-pie-chart-bottom.tc-pie-chart-icon .tc-pie-chart-icon-graph {
	margin-right: 0;
}
.tc-pie-chart-icon > div {
	font-size: 18px;
	margin-top: 5px;
	margin-bottom: -5px;
	text-align: center;
}
.tc-pie-chart-value {
	font-size: 20px;
	font-weight: bold;
}
.tc-pie-chart-color-rio-grande {
	color: #82bd41;
	stroke: #82bd41;
	fill: #82bd41;
}
.tc-pie-chart-color-lochmara {
	color: #0675C1;
	stroke: #0675C1;
	fill: #0675C1;
}

.tc-pie-chart-color-jaffa {
	color: #ea8330;
	stroke: #ea8330;
	fill: #ea8330;
}
.tc-pie-chart-color-chestnut-rose {
	color: #e96065;
	stroke: #e96065;
	fill: #e96065;
}
.tc-pie-chart-color-lightning-yellow {
	color: #f3c446;
	stroke: #f3c446;
	fill: #f3c446;
}
.tc-pie-chart-color-dove-gray {
	color: #555;
	stroke: #555;
	fill: #555;
}
.tc-pie-chart-color-silver-chalice {
	color: #ababab;
	stroke: #ababab;
	fill: #ababab;
}
.tc-pie-chart-color-alto {
	color: #d5d5d5;
	stroke: #d5d5d5;
	fill: #d5d5d5;
}
.tc-pie-chart-color-cyan {
	color: #91D1ED;
	stroke: #91D1ED;
	fill: #91D1ED;
}
.tc-pie-chart-color-welcome {
	color: #E2E2E2;
	stroke: #E2E2E2;
	fill: #E2E2E2;
}
.tc-pie-chart-icon .tc-pie-chart-icon-graph {
	margin-right: 15px;
	height: inherit;
	width: inherit;
}
