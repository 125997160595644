.report-summary {
	background: #f7f3ef;
	overflow: hidden;
	margin-bottom: 40px;
}
.report-summary .row {
	padding-top: 30px;
	padding-bottom: 40px;
}
hr {
	margin: 50px auto;
	max-width: 400px
}
.Report__logo {
	width: 150px;
	margin: 25px;
}
.Report__gauge {
	max-width: 300px;
	margin: auto;
	margin-top: 50px;
}
.Report__portlet {
	background: #f7f3ef;
	padding: 20px;
	margin-top: 20px;
}
.Report__portlet > h5 {
	font-size: 18px;
	font-weight: bold;
	text-transform: uppercase;
}
.Report__portlet > p {
	font-size: 16px;
}
.Report__productdesc {
	display: flex;
	font-size: 18px;
	margin-bottom: 20px;
}
.Report__productdesc strong{
	font-weight: bold;
	color: #05426F;
}
.Report__productdesc > p {
	margin-bottom: 0;
}
.Report__productlogo {
	width: 45px;
	margin-right: 20px;
	flex-shrink: 0;
}
.report-breakdown h2 {
	padding-top: 50px;
	margin-top: -50px;
}
table {
	background: #f8f3ef;
	border: 10px solid #f8f3ef;
	border-collapse: initial;
	border-radius: 3px;
	font-size: 18px;
	text-align: left;
}
.table td,
.table th {
	border: 0;
	padding: 0.25rem 0.5rem;
}
.unique-stat {
	font-size: 18px;
	line-height: 1.25;
}
.unique-stat span {
	display: block;
	font-family: 'gelasio';
	font-size: 3rem;
	color: #ea8330;
	margin-bottom: 10px;
}
.report-footer {
	background: #f7f3ef;
	padding: 50px 0;
}
.report-footer .btn i {
	margin-right: 10px;
}
.report-footer .footer-text {
	margin-top: 50px;
	font-size: 0.8rem;
	max-width: 50rem;
}
.report__text-small {
	font-size: 16px;
	line-height: 27px;
}
.Report__panel {
	padding: 16px 21px;
	text-align: left;
	box-sizing: border-box;
	width: 70%;
	max-width: 252px;
	border: 2px solid #EAEAEA;
	border-radius: 3px;
	background-color: #FFFFFF;
	box-shadow: 0 4px 14px 0 rgba(0,0,0,0.1);
	position: relative;
}
.Report__panel .fa-caret-down {
	float: right;
}
.Report__panel .progress {
	width: 100%;
	height: 5px;
	border-radius: 0;
	position: absolute;
	left: 0;
}
.Report__productlist {
	margin-top: 40px;
	margin-bottom: 50px;
}
#button-section-header {
	margin-bottom: 30px;
}
#button-section-cta {
	margin-top: 30px;
}
@media only screen and (min-width: 1200px) {
	.Report__gauge {
		margin-top: -10px;
		max-width: 360px;
	}
	.Report__portlet {
		margin-top: 0;
	}
}
@media print {
    .pagebreak {
        clear: both;
        page-break-after: always;
	}
}
